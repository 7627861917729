@font-face {
	font-family: 'IBMPlexSans-Medium';
	src: url('./frontend/fonts/IBMPlexSans-Medium.ttf') format('truetype');
}

body {
	font-family: 'IBMPlexSans-Medium';
	height: -webkit-fill-available;
}

html {
	font-family: 'IBMPlexSans-Medium';
	height: -webkit-fill-available;
}

.app-container {
	display: flex;
	width: 100%;
	height: 100vh;
}

/* Left Half */

.cmu-logo {
	position: relative;
	width: 330px;
	top: 20px;
	left: 20px;
}

.left-half {
	flex: 1;
	background-color: #3E3A56;
	padding: 20px;
	-ms-overflow-style: none;
	/* for Internet Explorer, Edge */
	scrollbar-width: none;
	/* for Firefox */
	overflow-y: scroll;
}

.left-half::-webkit-scrollbar {
	display: none;
	/* for Chrome, Safari, and Opera */
}

/* Input */

.input-container {
	flex: 1;
	padding: 23px;
	background-color: #3E3A56;
	color: #996875;
	position: relative;
	top: 31px;
}

#editable-text {
	white-space: pre-wrap;
	border: none;
	outline: none;
	background-color: transparent;
	height: 600px;
	font-size: 16px;
	color: #996875;
}

#editable-text:empty:before {
	content: attr(placeholder);
	color: #99687598;
}

/* Rounded Box */

.rounded-box {
	display: flex;
	width: 300px;
	height: 40px;
	padding: 0 5px;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 40px;
	left: 40px;
	z-index: 9999;
	text-align: right;
}

.group {
	display: flex;
}

/* Group 1 */

.group-1 {
	width: 66.67%;
	height: 100%;
	background-color: #EFD3D9;
	float: left;
	border-radius: 5px 5px 5px 5px;
}

/* Tool Tip*/

.group-1 .tooltip {
	visibility: hidden;
	width: 230px;
	height: 100px;
	border-radius: 5px;
	padding: 0 5px;
	background-color: #272537;
	color: #fff;
	text-align: left;
	border-radius: 6px;
	padding: 15px 10px 25px 15px;
	/* Position the tooltip */
	position: absolute;
	bottom: 50px;
	left: -10px;

	z-index: 1;
}

.text-1 {
	color: #EFD3D9;
}

.text-2 {
	color: #996875;
}

.triangle-down {
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	border-top: 35px solid #272537;
}

/* Checkbox */
.hidden-checkbox {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	overflow: hidden;
}

.checkbox-container {
	height: 15px;
	width: 15px;
	border-radius: 3px;
	position: relative;
	top: 13px;
	left: 10px;
	background-color: #272537;
	border-color: transparent;
	display: inline-block;
	cursor: pointer;
}

.checkmark {
	display: inline-block;
	position: relative;
	transform: rotate(320deg);
	bottom: 3px;
	left: 3px;
}

.tick {
	display: none;
	fill: #000;
	height: 15px;
	width: 15px;
}

.hidden-checkbox:checked+.checkbox-container .tick {
	display: block;
}

.tick {
	transition: transform 0.3s ease-in-out;
}

.hidden-checkbox:checked+.checkbox-container .tick {
	transform: rotate(45deg) scale(1);
}

.inline-text {
	position: relative;
	top: 23%;
	left: 10%;
	cursor: pointer;
	-webkit-user-select: none;
	/* Safari */
	-ms-user-select: none;
	/* IE 10 and IE 11 */
	user-select: none;
	/* Standard syntax */
}

#text {
	font-weight: bold;
}

.brackets {
	margin: auto 0 auto 35px;
	display: flex;
	gap: 5px;
}

.bracket1 {
	width: 12px;
	height: 20px;
	background-color: #272537;
	border-radius: 2px;
	font-weight: bolder;
	color: #996875;
	border: none;
	text-align: center;
	cursor: text;

}

.bracket2 {
	width: 12px;
	height: 20px;
	border-radius: 2px;
	background-color: #272537;
	font-weight: bolder;
	color: #996875;
	border: none;
	text-align: center;
	cursor: text;
}



/* Right Half */

.right-half {
	flex: 1;
	background-color: #272538;
	padding: 20px;
	color: #996875;
	-ms-overflow-style: none;
	/* for Internet Explorer, Edge */
	scrollbar-width: none;
	/* for Firefox */
	overflow-y: scroll;
	position: relative;
}

.right-half::-webkit-scrollbar {
	display: none;
	/* for Chrome, Safari, and Opera */
}

/* Output */

.output-container {
	flex: 1;
	padding: 20px;
	background-color: #272538;
	color: #996875;
	white-space: pre-line;
	position: relative;
	top: 70px;
}

.output {
	position: absolute;
	left: 40px;
}

/* Tranpose Buttons */

svg {
	width: 40px;
	height: 40px;
	fill: #000;
	cursor: pointer;

	-webkit-user-select: none;
	/* Safari */
	-ms-user-select: none;
	/* IE 10 and IE 11 */
	user-select: none;
	/* Standard syntax */
}

.toggle-button-container {
	width: 55px;
	height: 55px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	-webkit-user-select: none;
	/* Safari */
	-ms-user-select: none;
	/* IE 10 and IE 11 */
	user-select: none;
	/* Standard syntax */

}

.output-buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3px;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
}

/* Copy Button */

.copy-button-container {
	align-items: center;
	justify-content: center;
	position: relative;
	/* Set container's position to relative */
	width: 200px;
	/* Adjust container width as needed */
}

.copy-button {
	background: #1d1c28 url(./frontend/svg/Clipboard.svg) no-repeat 2px center;
	visibility: visible;
	border: 8px solid;
	border-color: #1d1c28;
	border-radius: 5px;
	position: fixed;
	z-index: 9999;
	top: 40px;
	left: calc(75% - 80px);
	cursor: pointer;
	font-family: 'IBMPlexSans-Medium';
	width: 175px;
	color: #EFD3D9;
	transition: background-color 0.3s, color 0.3s, border-color 0.3s, transform 0.3s;
	transform-origin: center top;
	-webkit-user-select: none;
	/* Safari */
	-ms-user-select: none;
	/* IE 10 and IE 11 */
	user-select: none;
	/* Standard syntax */
}

.copy-text {
	position: relative;
	left: 13px;
}


/* Tablet */

@media only screen and (max-width: 850px) {
	.app-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
	}

	.toggle-button-container {
		width: 55px;
		height: 55px;
		cursor: pointer;
		background-color: transparent;
		border: none;
		position: fixed;
		transform: translate(325%, -50%);
	}

	.output-container {
		height: calc(100% - 40px);
		/* Consider padding on both top and bottom */
		width: calc(100% - 40px);
		/* Consider padding on both left and right */
		padding: 20px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.output-buttons {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
		position: fixed;
		transform: translate(-64%, -93%);
		gap: 5px;
	}

	.copy-button {
		display: flex;
		width: 42px;
		height: 42px;
		border-radius: 5px;
		font-size: 0;
		text-indent: -9999px;
		user-select: none;
		background: #1d1c28 url(./frontend/svg/Clipboard.svg) no-repeat 3px center;
		visibility: visible;
		align-items: center;
		position: fixed;
		z-index: 9999;
		right: 40px;
		top: calc(100% - 80px);
		left: auto;
		cursor: pointer;
		transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
		transform-origin: center top;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */
	}

	.copy-button:hover {
		background-color: #EFD3D9;
		/* Swap background color on hover */
		/* Swap text color on hover */
		border-color: #EFD3D9;
		/* Change border color on hover */
		transform: scale(1.05);
		/* Scale the button on hover */
	}

	.copy-button:active {
		transform: scale(0.9);
		/* Shrink size by 10% on click */
		transition: transform 0s;
		/* Instant shrink, no transition */
	}

	.copy-button:active:focus {
		transition: transform 0.3s ease-out;
		/* Ease-out to original size */
		transform: scale(1);
		/* Return to original size after click */
	}
}

/* Phone */

@media only screen and (max-width: 484px) {

	/* For mobile phones: */
	.ai-button {
		background-color: transparent;
		color: transparent;
		cursor: not-allowed;
		pointer-events: none;
	}

	.rounded-box {
		display: flex;
		width: 80px;
		height: 40px;
		border-radius: 5px;
		justify-content: space-between;
		align-items: center;
		background-color: #EFD3D9;
		border: 1px solid #ccc;
		position: fixed;
		bottom: 40px;
		left: 40px;
		color: transparent;
		user-select: none;

	}

	.checkbox-container {
		margin-right: 112px;
		left: 9px;
	}

	.inline-text {
		position: relative;
		width: 0px;
		height: 0px;
		cursor: pointer;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */
	}

	.group-2 {
		visibility: hidden;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */
	}

	.ai-text {
		visibility: hidden;
	}

	.ai-button {
		visibility: hidden;
	}

	.arrow-right {
		visibility: hidden;
	}

	.output {
		position: absolute;
		left: 23px;
		padding-bottom: 110px
	}

	.brackets {
		margin: auto -90px;
		display: flex;
		gap: 5px;
	}

	.cmu-logo {
		position: relative;
		width: min(90%, 330px);
		top: 2 0px;
		left: 50%;
		transform: translateX(-50%);
	}

	.copy-button {
		display: flex;
		width: 44px;
		height: 44px;
		border-radius: 5px;
		color: transparent;
		user-select: none;
		background: #1d1c28 url(./frontend/svg/Clipboard.svg) no-repeat 3px center;
		visibility: visible;
		align-items: center;
		position: fixed;
		z-index: 9999;
		top: calc(100% - 83px);
		left: 85%;
		transform: translateX(-50%);
	}

	.lds-default {
		display: none;
	}

}

/* Large Screen */

@media only screen and (min-width: 1024px) {
	#editable-text {
		white-space: pre-wrap;
		border: none;
		outline: none;
		background-color: transparent;
		height: 600px;
		font-size: 1vw;
		color: #996875;
	}

	.output-container {
		flex: 1;
		padding: 20px;
		background-color: #272538;
		color: #996875;
		white-space: pre-line;
		position: relative;
		font-size: 1vw;
		top: 70px;
	}
}

/* Animations */

@media only screen and (min-width: 851px) {
	.group-1:hover .tooltip {
		visibility: visible;
	}

	/* Define initial transition for size changes */
	.output-buttons .button {
		transition: transform 0.15s ease-out;
	}

	/* Hover animation: Increase size */
	.output-buttons .button:hover {
		transform: scale(1.1);
		/* Increase size by 10% on hover */
	}

	/* Click animation: Shrink and ease-out to original size */
	.output-buttons .button:active {
		transform: scale(0.9);
		/* Shrink size by 10% on click */
		transition: transform 0s;
		/* Instant shrink, no transition */
	}

	/* Ease-out animation back to original size after click */
	.output-buttons .button:active:focus {
		transition: transform 0.3s ease-out;
		/* Ease-out to original size */
		transform: scale(1);
		/* Return to original size after click */
	}

	.copy-button:hover {
		background-color: #EFD3D9;
		/* Swap background color on hover */
		color: #1d1c28;
		/* Swap text color on hover */
		border-color: #EFD3D9;
		/* Change border color on hover */
		transform: scale(1.05);
		/* Scale the button on hover */
	}

	.copy-button:active {
		transform: scale(0.9);
		/* Shrink size by 10% on click */
		transform-origin: center;
		transition: transform 0s;
		/* Instant shrink, no transition */
	}

	.copy-button:active:focus {
		transition: transform 0.3s ease-out;
		/* Ease-out to original size */
		transform: scale(1);
		/* Return to original size after click */
	}

	.copy-text:hover {

		/* Change border color on hover */
		transform: scale(1.05);
		/* Scale the button on hover */
	}

	.copy-text:active {
		transform: scale(0.9);
		/* Shrink size by 10% on click */
		transform-origin: center;
		/* transition: transform 0s; */
		/* Instant shrink, no transition */
	}

	.copy-text:active:focus {
		transition: transform 0.3s ease-out;
		/* Ease-out to original size */
		transform: scale(1);
		/* Return to original size after click */
	}
}